<template>
    <v-row>
        <v-col>
            <v-switch label="Placeholder Toggle" />
            <v-text-field label="Placeholder Text Field" />
        </v-col>
    </v-row>
</template>

<script>
export default {}
</script>

<style>

</style>